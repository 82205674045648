export default {
  ESI_WILD_BOOK: 1,
  ESI_LEMON: 2,
  ESI_ORANGE: 3,
  ESI_PLUM: 4,
  ESI_CHERRY: 5,
  ESI_A: 6,
  ESI_K: 7,
  ESI_Q: 8,
  ESI_J: 9,
  ESI_10: 10,
  APPLE: 12,

}
