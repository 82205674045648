import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import EntryPoint from 'Engine/EntryPoint';
import eBaseStateTypes from 'Engine/enums/eBaseStateTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class UiAdapterExtended extends UiAdapter {
  constructor(...props) {
    super(...props);
    this._win = 0;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('stateEnd:FreespinsAddState', this.showUi, this);
    GlobalDispatcher.add('stateEnd:FreeSpinsOutState', this.showUi, this);
    GlobalDispatcher.add('showBonusState:showBonusLabelEnd', this.onShowBonusLabelEnd, this);
  }

  onWin = () => {
    this._updateUiForWin();
    this._win = this._calculateBaseWin();
    this.ControllerUi.setWin(this._win);

    if (!this._isFreeSpinsActive) {
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
    }
  }

  onExpand = () => {
    this._updateUiForWin();
    this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);

    if (!this._isFreeSpinsActive) {
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
    }
  }

  onShowBonusLabelEnd(event) {
    this._win += event.params;
    this.ControllerUi.setWin(this._win);
  }

  extraStateHandlers = {
    'requestRespinRollingState': () => {
      this.stateHandlers[eBaseStateTypes.EBST_REQUEST_ROLLING]();
      this._updateUiForRespin();
    },
    'minimalRespinRollingState': this.stateHandlers[eBaseStateTypes.EBST_MINIMAL_ROLLING],
    'stopRespinRollingState': this.stateHandlers[eBaseStateTypes.EBST_STOP_ROLLING],
    'showWinningsState': this.onWin,
    'showExpandedWinState': this.onExpand,
    'FreespinsAddState': () => {
      this.hideUi();
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.toggleSpinButton(false);

      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
      this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins, this._bonusLabelText);
      this.ControllerUi.toggleInfoButton(false);
      this.ControllerUi.toggleTournamentsLabel(false);
      this.ControllerUi.showTotalWin();
    },
    'FreeSpinsOutState': this.stateHandlers[eBaseStateTypes.EBST_FREE_SPINS_OUT],
    'requestRollingState': () => {
      this.ControllerUi.toggleStopButton(true);
      this._win = 0;
    },
    default: () => {
    },
  };

  _updateUiForWin() {
    this.ControllerUi.showStopButton();
    this.ControllerUi.toggleStopButton(true);
    this.ControllerUi.toggleMenuButton(false);
    this.ControllerUi.toggleMaxBetButton(false);
    this.ControllerUi.toggleBetSelectors(false);
    this.ControllerUi.toggleInfoButton(false);
    this.ControllerUi.toggleAutoPlayButton(false);
  }

  _updateUiForRespin() {
    this.ControllerUi.toggleStopButton(false);
    this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
  }

  _calculateBaseWin() {
    const specialData = EntryPoint.GameModel.specialData || {};
    const expandWin = specialData.win || 0;
    const totalWin = EntryPoint.GameModel.totalWin || 0;
    const bonusGameWins = EntryPoint.GameModel.freeSpins.bonusGameWins;

    let bonusTotal = 0;
    for (let key in bonusGameWins) {
      if (bonusGameWins.hasOwnProperty(key)) {
        bonusTotal += bonusGameWins[key];
      }
    }

    return totalWin - bonusTotal - expandWin;
  }

  _isFreeSpinsActive() {
    return EntryPoint.GameModel.freeSpinsInActiveState;
  }
}
