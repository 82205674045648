import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import { getRandom } from 'Engine/utils/getRandom';
import { Spine } from 'pixi-spine';
import { eSounds } from '../enums/eSounds';

export default class BaseUiContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.init();
    this.initPurchaseAnimation();
    this.addListeners();
    this.addActions();
  }

  init() {
    this.winContainer = this.getChildByName('winContainer');
    this.winContainer.setValue(EntryPoint.GameModel.totalWin);
    this.winContainer.alpha = 0;

    this.purchaseButton = this.getChildByName('purchaseButton');
    this.purchaseButton.enabled = true;
    makeHitArea(this.purchaseButton);
    this.purchaseButtonAnimationContainer = this.getChildByName('purchaseButtonAnimation');
    if (OPWrapperService.config.disableBuyBonus) this.removeChild(this.purchaseButton, this.purchaseButtonAnimationContainer);
  }

  initPurchaseAnimation() {
    const spineResource = PIXI.Assets.get('purchase_button');
    if (spineResource) {
      this.purchaseButtonAnimation = new Spine(spineResource.spineData);
      this.purchaseButtonAnimation.state.addListener({
        complete: this.loopPurchaseAnimation.bind(this)
      });
      this.purchaseButtonAnimationContainer.addChild(this.purchaseButtonAnimation);
    }
  }

  loopPurchaseAnimation() {
    if (!this.purchaseButtonAnimation) {
      this.initPurchaseAnimation();
    }

    this.purchaseButtonAnimation.visible = false;
    let delay = getRandom(2000, 10000);

    this.purchaseAnimationTimeout = setTimeout(() => {
      if (!this.purchaseButtonAnimation) return;

      this.purchaseButtonAnimation.visible = true;
      this.purchaseButtonAnimation.state.setAnimation(0, 'animation', false);
    }, delay);
  }

  stopPurchaseAnimation() {
    if (this.purchaseAnimationTimeout) {
      clearTimeout(this.purchaseAnimationTimeout);
      this.purchaseAnimationTimeout = null;
    }

    if (this.purchaseButtonAnimation) {
      this.purchaseButtonAnimation.destroy();
      this.purchaseButtonAnimation = null;
    }
  }

  addListeners() {
    GlobalDispatcher.add('showBonusState:showBonusLabelEnd', this.onShowBonusLabelEnd, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onShowBonusLabelEnd(event) {
    this.winContainer.addValue(event.params);
  }

  onGameResized(data) {
    this.stopPurchaseAnimation();
    if ( data.isLandscape) {
      this.goToFrame(2);
    } else {
      this.goToFrame(1);
    }
    this.init();
    this.addActions();
    this.onStateChanged({ params: StatesManager.currentState.stateData.name });
    GlobalDispatcher.dispatch('uiContainer:winContainerData', this.winContainer);
  }

  addActions() {
    this.purchaseButton.actions = {
      click: {
        callback: this.onPurchaseClick, context: this
      },
      touchend: {
        callback: this.onPurchaseClick, context: this
      }
    }
  }

  onPurchaseClick() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
    GlobalDispatcher.dispatch('ui:openPurchaseClick');
  }

  onStateChanged(event) {
    switch (event.params) {
      case 'initState':

        break;
      case 'showStartScreenState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
        this.stopPurchaseAnimation();
        break;
      case 'waitUserInteractionState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = false;
        this.stopPurchaseAnimation();
        break;
      case 'idleState':
        this.purchaseButton.enabled = !EntryPoint.GameModel.isFreeSpinsMode;
        this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
        this.loopPurchaseAnimation();
        break;
      case 'requestRespinRollingState':
      case 'requestRollingState':
      case 'minimalRollingState':
      case 'minimalRespinRollingState':
      case 'stopRollingState':
      case 'stopRespinRollingState':
      case 'showBonusState':
      case 'expandSymbolsState':
      case 'showWinningsState':
      case 'showExpandedWinState':
      case 'requestBonusState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;
      case 'FreespinsAddState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = false;
        this.stopPurchaseAnimation();
        break;
      case 'FreespinsOutState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = true;
        this.stopPurchaseAnimation();
        break;
      default:
        this.purchaseButton.enabled = false;
        break;
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.stopPurchaseAnimation();
      this.purchaseButton.enabled = false;
      this.purchaseButton.visible = false;
    }
  }

  destroy() {
    super.destroy();

    GlobalDispatcher.remove('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.remove(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }
}
