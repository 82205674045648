import EntryPoint from 'Engine/EntryPoint';

import gameConfig from '../../assets/config';
import starTexture from '../../assets/images/star_particle_fred_small.png';
import dotTexture from '../../assets/images/dot.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import InterExtraBold from '../../assets/fonts/inter/Inter-ExtraBold.woff2';
import InterSemiBold from '../../assets/fonts/inter/Inter-SemiBold.woff2';

import Config from "../Config";

import {getAssetsScale} from "Engine/utils/getAssetsScale";
import { soundsPreloaderConfig } from '../enums/eSounds';

const scale = getAssetsScale({
  availableScales: ['0.7','0.75','0.8','0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
})

const assets = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;
import flashlib from '../../assets/exported/gameMobile/FlashLib.json';
import animationTypes from '../enums/animationTypes';

export default {
  assets: [
    {name: 'GameAssets', path: assets, extension: 'json'},
    {name: 'GameFlashLib', path: flashlib, extension: 'json'},
    {name: 'gameConfig', path: gameConfig, extension: 'json'},
    {name: animationTypes.SYMBOLS, path: 'animations/symbols/fruityboo_symbols_pro.json', extension: 'json'},
    {name: animationTypes.PURCHASE_BUTTON, path: 'animations/purchase/bonus_purchase_button/button_bones_pro.json', extension: 'json'},
    {name: animationTypes.LINES_SPINE, path: 'animations/winlines/winlines_pro.json', extension: 'json'},
    {name: animationTypes.FREE_SPINS_WINDOW, path: 'animations/freeSpinsBook/freespins_book_fruitybook_pro.json', extension: 'json'},
    {name: animationTypes.MULTIPLIER, path: 'animations/multiplier/skeleton.json', extension: 'json'},
    {name: animationTypes.BACKGROUND, path: 'animations/background/skeleton.json', extension: 'json'},
    {name: animationTypes.COIN, path: 'animations/coins/skeleton.json', extension: 'json'},
    {name: 'fb_font', path: 'fonts/fb_font/f_b_font.xml', extension: 'xml'},
    {name: 'apple_font', path: 'fonts/apple_font/apple_font.xml', extension: 'xml'},
    {name: 'starsParticle', path: starTexture, extension: 'png'},
    {name: 'dotParticle', path: dotTexture, extension: 'png'}
  ],
  delayedAssets: [
    {name: animationTypes.BIG_WIN, path: 'animations/bigWins/DWB_fruitybook_pro.json', extension: 'json'},
    {name: animationTypes.PURCHASE, path: 'animations/purchase/bonus_purchase/purchase_bonuses.json', extension: 'json'},
    {name: animationTypes.ANTISIPATOR, path: 'animations/antisipator/skeleton.json', extension: 'json'},
  ],
  fonts: [
    { name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'InterExtraBold',
      path: InterExtraBold,
    },
    {
      name: 'InterSemiBold',
      path: InterSemiBold,
    },
  ],
  sounds: soundsPreloaderConfig,
}
