import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../enums/eSounds';

export default class ExpandingSymbolsContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.symbols = [];
        this.positions = [];
        this.placeIndex = -1;
        this.delay = 250;
        this.timeout = null;

        this.init();
        this.addListeners();
    }

    init() {
        let configData = EntryPoint.configData;
        let reelsConfig = configData.reels;
        for (let i = 0; i < reelsConfig.reelsCount; i++) {
            for (let j = 0; j < reelsConfig.symbolsInReel; j++) {
                this[`symbol_${i}_${j}`] = this.getChildByName(`symbol_${i}_${j}`);
                this[`symbol_${i}_${j}`].y = (configData.symbols.height + configData.reels.symbolTopOffset) * j;
                this[`symbol_${i}_${j}`].visible = false;
                this.symbols.push(this[`symbol_${i}_${j}`]);
            }
        }
    }

    addListeners() {
        GlobalDispatcher.add('stateStart:expandSymbolsState', this.onShowExpandedWinStateStart, this);
        GlobalDispatcher.add('stateEnd:expandSymbolsState', this.onExpandSymbolsStateEnd, this);
        GlobalDispatcher.add('stateEnd:showExpandedWinState', this.onShowExpandedWinStateEnd, this);
    }

    onShowExpandedWinStateStart() {
        this.positions = [];
        this.placeIndex = -1;
        EntryPoint.GameModel.specialData.positions.forEach((path) => {
            for (let i = 0; i < 3; i++) {
                this.positions.push([path[0], i]);
            }
        });
        this.placeNextSymbol();
    }

    placeNextSymbol() {
        this.placeIndex++;
        if (this.placeIndex < this.positions.length) {
            this.placeSymbol(this.placeIndex);
            this.timeout = setTimeout(() => {
                this.placeNextSymbol(this.placeIndex);
            }, this.delay);
        } else {
            GlobalDispatcher.dispatch('allExpandedSymbolShowed');
            this.hideFrames();
        }
    }

    placeSymbol(index) {
        let path = this.positions[index];
        let symbol = this[`symbol_${path[0]}_${path[1]}`];
        symbol.changeSymbol(EntryPoint.GameModel.specialData.symbol);
        symbol.visible = true;
        symbol.showFrame();
        SoundManager.play(eSounds.SYMBOL_EXPAND.name, eSounds.SYMBOL_EXPAND.volume)
    }

    onExpandSymbolsStateEnd() {
        clearTimeout(this.timeout);
        for (let i = this.placeIndex + 1; i < this.positions.length; i++) {
            this.placeSymbol(i);
        }
        this.reset();
    }

    onShowExpandedWinStateEnd() {
        this.reset();
    }

    hideFrames() {
        this.symbols.forEach(symbol => {
            symbol.hideFrame();
        });
    }

    reset() {
        clearTimeout(this.timeout);
        this.symbols.forEach((symbol) => {
            symbol.visible = false;
        });
        // this.containers.forEach(el => el.goToFrame(1));
        this.positions = [];
        this.placeIndex = -1;
        //this.removeChildren();
    }
}
