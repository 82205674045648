import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from '../libs/game-engine/src/EntryPoint';

export default class GameInfoContent extends FlashLib.MovieClip{
  constructor(data, displayData) {
    super(data, displayData);

    this.currentFrame = 1;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('gameInfo:backClick', this.reset.bind(this));
    GlobalDispatcher.add('gameInfo:changePage', this.changeFrame.bind(this));
  }

  setMultipliers() {
    switch (this.currentFrame) {
      case 4:
        let scatterPays = this.children[0].getChildByName('paytable_p3_coefs');
        let coefs = [...EntryPoint.GameModel.paytableInfo['1']];
        coefs.forEach((coef, i) => coefs[i] = `×${coef}`);
        scatterPays.text = coefs.join('\n');
        break;
      case 7:
        for (let i = 1; i < Object.keys(EntryPoint.GameModel.paytableInfo).length; i++){
          let element = this.children[0].getChildByName(`paytable_p6_coefs${i}`);
          let coefs = [...EntryPoint.GameModel.paytableInfo[`${i + 1}`]];
          coefs.forEach((coef, i) => coefs[i] = `×${coef}`);
          element.text = coefs.join('\n');
        }
        break;
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params;
    this.goToFrame(this.currentFrame);
    this.setMultipliers();
  }

  reset() {
    this.currentFrame = 1;
    this.goToFrame(this.currentFrame);
  }
}
