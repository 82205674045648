import FlashLib from 'flashlib_onlyplay';
import { gsap, Power1 } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SymbolWinLabel from './symbolWinLabel';

export default class BonusLabelsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('uiContainer:winContainerData', this.addWinContainerPosition.bind(this));
    GlobalDispatcher.add('showBonusState:showBonusLabel', this.showLabel.bind(this));
    GlobalDispatcher.add('stateEnd:showBonusState', this.onShowBonusStateEnd.bind(this));
  }

  addWinContainerPosition(event) {
    const winContainer = event.params;
    const position = this.toLocal({ x: 0, y: 0 }, winContainer);
    this.winContainerPosition = { x: position.x + winContainer.width / 2, y: position.y };
  }

  showLabel(event) {
    this.active = true;
    const { label, callback } = event.params;

    const labelPosition = this.toLocal({ x: 0, y: 0 }, label.label);

    const localLabel = new SymbolWinLabel(this, {
      fontName: 'apple_font',
      fontSize: 30
    });

    localLabel.text = label.text;
    localLabel.x = labelPosition.x;
    localLabel.y = labelPosition.y;

    this.addChild(localLabel.label);

    const middlePoint = {
      x: (localLabel.x + this.winContainerPosition.x) / 2 - localLabel.x / 2,
      y: (localLabel.y + this.winContainerPosition.y) / 2 - 100,
    };

    gsap.to(localLabel.label,
      {
        motionPath: {
          path: [{ x: localLabel.x, y: localLabel.y }, {
            x: middlePoint.x,
            y: middlePoint.y
          }, { x: this.winContainerPosition.x, y: this.winContainerPosition.y }],
        },
        duration: 0.5,
        ease: Power1.easeInOut,
        onComplete: () => {
          GlobalDispatcher.dispatch('showBonusState:showBonusLabelEnd', Number(localLabel.text));
          localLabel.destroy({ children: true });
          if (!this.active) return;
          callback();
        }
      });
  }

  onShowBonusStateEnd() {
    this.active = false;
  }
}
