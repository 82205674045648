import EntryPoint from 'Engine/EntryPoint';
import SymbolAnimation from '../symbols/SymbolAnimation';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import * as PIXI from 'pixi.js';
import SymbolWinLabel from '../bonusLabels/symbolWinLabel';
import eSymbolIndex from '../enums/eSymbolIndex';
import BaseSymbolsAnimationsContainer from './BaseSymbolsAnimationsContainer';

export default class SymbolsAnimationsContainer extends BaseSymbolsAnimationsContainer {
  constructor(data, displayData) {
    super(data, displayData);

    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRect(-139, -115, (267 + 5) * 5, 226 * 3);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;

    this.relations = {
      [eSymbolIndex.ESI_WILD_BOOK]: 'book_opening',
      [eSymbolIndex.ESI_LEMON]: 'lemon',
      [eSymbolIndex.ESI_ORANGE]: 'orange',
      [eSymbolIndex.ESI_PLUM]: 'plum',
      [eSymbolIndex.ESI_CHERRY]: 'cherry',
      [eSymbolIndex.ESI_A]: 'a_royal',
      [eSymbolIndex.ESI_K]: 'k_royal',
      [eSymbolIndex.ESI_Q]: 'q_royal',
      [eSymbolIndex.ESI_J]: 'j_royal',
      [eSymbolIndex.ESI_10]: '10_royal',
      [eSymbolIndex.APPLE]: 'apple_idle',
    };

    this.init();
  }

  init() {
    this.labels = {};
    this.symbolAnimations = {};

    let configData = EntryPoint.configData;
    let reelsConfig = configData.reels;
    for (let i = 0; i < reelsConfig.reelsCount; i++) {
      for (let j = 0; j < reelsConfig.symbolsInReel; j++) {
        this[`place_${i}_${j}`] = this.getChildByName(`place_${i}_${j}`);
        const symbolAnimation = new SymbolAnimation(null, [i, j]);
        symbolAnimation.on('animationComplete', this.onAnimationComplete.bind(this));

        this.symbolAnimations[`${i}_${j}`] = { reel: i, position: j, animation: symbolAnimation };
        this[`place_${i}_${j}`].addChild(symbolAnimation);

        this.labels[`${i}_${j}`] = new SymbolWinLabel(this[`place_${i}_${j}`], {
          fontName: 'apple_font',
          fontSize: 30
        });

        this.labels[`${i}_${j}`].x = 0;
        this.labels[`${i}_${j}`].y = 0;
      }
    }
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('reel:stopped', this.onReelStopped, this);
    GlobalDispatcher.add('stateStart:showExpandedWinState', this.onShowExpandedWinStateStart, this);
    GlobalDispatcher.add('stateStart:showBonusState', this.onShowBonusStateStart, this);
  }

  // show(position) {
  //     const frame = this.winFrames[position[0]][position[1]];
  //     frame.goToFrame(2);
  //     this.showingWinFrames.push(frame);
  // }
  //
  // hideAllFrames() {
  //     this.showingWinFrames.forEach(frame => {
  //         frame.goToFrame(1);
  //     });
  //
  //     this.showingWinFrames = [];
  // }

  onShowBonusStateStart() {
    this.labelsToFly = Object.values(this.labels).filter(label => label.active);
    this.makeLabelFly();
  }

  makeLabelFly() {
    if (!this.labelsToFly.length) {
      return GlobalDispatcher.dispatch('allBonusesShown');
    }

    GlobalDispatcher.dispatch('showBonusState:showBonusLabel', {
      label: this.labelsToFly.shift(),
      callback: this.makeLabelFly.bind(this)
    });
  }

  showWinningSymbols(data) {
    this.removeAnimations(true);
    data.params.path.forEach((pathData) => {
      this.addAnimation(pathData);
    });
  }

  onReelStopped(event) {
    let reelIndex = event.params;
    let reelData = EntryPoint.GameModel.reels[reelIndex];

    reelData.forEach((symbolId, index) => {
      if (symbolId === 1) {
        this.addAnimation([reelIndex, index], 'book_idle', '');
      }
      if (symbolId === EntryPoint.configData.symbols.specialSymbolId) {
        this.addAnimation([reelIndex, index], 'apple_stop', '', EntryPoint.configData.symbols.specialSymbolId);
        if (EntryPoint.GameModel.freeSpinsInActiveState) {
          this.addLabel(reelIndex, index);
        }
      }
    });
  }

  onShowExpandedWinStateStart() {
    let animationName = this.relations[EntryPoint.GameModel.specialData.symbol];
    EntryPoint.GameModel.specialData.positions.forEach((path) => {
      for (let i = 0; i < 3; i++) {
        this.addAnimation([path[0], i], animationName, '', EntryPoint.GameModel.specialData.symbol, true);
        this[`place_${path[0]}_${i}`].goToFrame(2);
      }
    });
  }

  addLabel(reel, index) {
    this.labels[`${reel}_${index}`].text = `${EntryPoint.GameModel.freeSpins.bonusGameWins[`${reel}${index}`]}`;
    // this.labels[`${reel}_${index}`].active = true;
  }

  addAnimation(pathData, animationName, type = '', symbolId = null, loop = undefined) {
    let id = symbolId || EntryPoint.GameModel.reels[pathData[0]][pathData[1]];
    if (!animationName) {
      animationName = this.relations[id];
    }
    const key = `${pathData[0]}_${pathData[1]}`;
    let symbolAnimation = this.symbolAnimations[key];
    symbolAnimation.animation.id = id;
    symbolAnimation.animation.start(animationName, loop);
  }

  onAnimationComplete(animation) {
    if (animation.id === EntryPoint.configData.symbols.specialSymbolId) {
      animation.animation.state.setAnimation(0, this.relations[EntryPoint.configData.symbols.specialSymbolId], false);
    }
    GlobalDispatcher.dispatch('symbolAnimation:complete', animation.symbolPosition);
  }

  removeAnimations(leaveSpecialSymbols) {
    Object.values(this.symbolAnimations).forEach((data, index, arr) => {
      if (data.animation) {
        if (leaveSpecialSymbols && data.animation.id === EntryPoint.configData.symbols.specialSymbolId) return;
        data.animation.stop();
      }
      this[`place_${data.reel}_${data.position}`].goToFrame(1);
    });

    if (leaveSpecialSymbols) return;

    Object.values(this.labels).forEach(label => {
      label.text = '';
    })
  }
}
