import UiAdapter from './uiAdapterExtended';
import { eSounds } from '../enums/eSounds';

const uiConfig = {
  H: {
    offsets: {
      right_panel: { top: 0, left: -11 },
      tournaments_panel: { top: 70, left: -1440 },
      bottom_panel: { top: -48, left: 0 },
    }
  },
  V: {
    offsets: {
      top: -140,
      left: 0,
    }
  }
};

const initUi = () => {
  const ControllerUi = window.OPWrapperService.initUi(uiConfig);
  new UiAdapter(ControllerUi).init({
    bonusLabelText: 'FREESPINS',
    clickSound: { soundName: eSounds.EST_BUTTON_CLICK.name, volume: 1 }
  });
}

export {
  initUi,
}
