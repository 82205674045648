import FlashLib from "flashlib_onlyplay";
import { shadowText } from '../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class BaseWinContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.lastValue = 0;
    this.valueText = null;

    this.init();
  }

  init() {
    this.titleText = this.getChildByName('ui_win_label');
    this.valueText = this.getChildByName('valueText');

    this.titleText.style = {...this.titleText.style, ...shadowText};
  }

  setValue(value) {
    this.valueText.text = value ? MoneyFormat.format(value, true) : '';
    this.lastValue = value ? value : 0;
  }

  addValue(value) {
    if (!value) return;
    this.setValue(this.lastValue + value);
  }
}
