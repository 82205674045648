import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import Animation from 'Engine/base/animation/Animation';
import SoundManager from 'Engine/soundManager/SoundManager';
import * as PIXI from 'pixi.js';
import WinAmountAnimated from '../winAmountAnimated/winAmountAnimated';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import { eSounds } from '../enums/eSounds';
import ParticleSystem from '../particles/particleSystem';
import particlesConfig from '../particles/particleConfig';
import { getRandom } from 'Engine/utils/getRandom';
import animationTypes from '../enums/animationTypes';

export default class FreeSpinsWindowContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._page = EntryPoint.GameModel.isFreeSpinsMode ? 1 : -1;
    this.iterateSymbolsCount = 10;
    this._isVisible = false;
    this.interactive = true;

    this.init();
    this.initParticles();
    this.addListeners();
    this.hide();

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onGameResized(data) {
    if (EntryPoint.mobile && data.orientation === 'V') {
      const scale = 0.7;
      this.wrapper.x = this.wrapper.x === 0 ? this.wrapper.x + this.wrapper.displayData.width / 4.3 : this.wrapper.x;
      this.wrapper.y = this.wrapper.y === 0 ? this.wrapper.y + this.wrapper.displayData.height / 8 : this.wrapper.y;
      this.wrapper.scale.set(scale);
    } else {
      this.wrapper.x = 0;
      this.wrapper.y = 0;
      this.wrapper.scale.set(1);
    }
  }

  init() {
    this.wrapper = this.getChildByName('wrapper');
    this.content = this.wrapper.getChildByName('content');
    this.coinsAnimationContainer = this.content.getChildByName('coinsAnimationContainer');
    this.animationContainer = this.wrapper.getChildByName('animationContainer');

    this.content.visible = false;
    this.bookAnimation = new Animation(animationTypes.FREE_SPINS_WINDOW);
    // this.getChildAt(1).visible = false;
    this.animationContainer.addChild(this.bookAnimation);
  }

  addListeners() {
    this.bookAnimation.on('complete', this.onBookAnimationComplete, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onBookAnimationComplete() {
    this.content.visible = true;
    switch (this._page) {
      case 1:
        this.initPage1();
        break;
      case 2:
        this.initPage2();
        break;
    }
  }

  hide() {
    clearTimeout(this._timeout);
    this.bookAnimation.reset();
    EntryPoint.GameModel.isFreeSpinsMode = this._page === 1;
    SoundManager.stop(eSounds.SCATTER_WIN.name);
    if (this.freeSpinsCountLabel) {
      this.freeSpinsCountLabel.destroy();
      this.freeSpinsCountLabel = null;
    }
    this.content.visible = false;
    this.visible = false;
    if (this.coinsContainer) {
      this.coinsContainer.visible = false;
      this.coinParticles.stop();
    }
    if (this.winAmountCounter) {
      this.winAmountCounter.stop();
      this.winAmountCounter.label.destroy({ children: true });
      this.winAmountCounter = null;
    }
    this._isVisible = false;
    this._removeDynamicListeners();
  }

  show(value) {
    this._page = value;
    EntryPoint.GameModel.isFreeSpinsMode = this._page === 1;
    this.playBookAnimation();
    SoundManager.play(eSounds.SCATTER_WIN.name, eSounds.SCATTER_WIN.volume, false, 'winning');
    this.visible = true;
    this._isVisible = true;

    if (this._page === 2) this._addDynamicListeners();
  }

  playBookAnimation() {
    this.bookAnimation.reset();
    this.bookAnimation.play(false, 'book_open');
  }

  initPage1() {
    this.content.goToFrame(1);
    this.symbol = this.content.getChildByName('symbol');
    this.titleText = this.content.getChildByName('freeSpins_start_label1');
    this.title2Text = this.content.getChildByName('freeSpins_start_label2');
    this.freeSpinsAmountContainer = this.content.getChildByName('freeSpinsAmountContainer');
    this.title3Text = this.content.getChildByName('freeSpins_start_label3');
    this.showFreeSpinsCount();

    let symbolsToIterate = [EntryPoint.GameModel.freeSpins.specialSymbol];

    for (let i = 1; i <= this.iterateSymbolsCount; i++) {
      let randomSymbol = getRandom(2, 10);

      while (randomSymbol === symbolsToIterate[symbolsToIterate.length - 1]) {
        randomSymbol = getRandom(2, 10);
      }

      symbolsToIterate.push(randomSymbol);
    }

    this.symbol.iterateOverSymbols(symbolsToIterate);
  }

  showFreeSpinsCount() {
    const freeSpinsAmount = EntryPoint.GameModel.freeSpins.added || EntryPoint.GameModel.freeSpins.left;

    this.freeSpinsCountLabel = new PIXI.BitmapText(freeSpinsAmount.toString(), { fontName: 'fb_font', fontSize: 120 });

    this.freeSpinsCountLabel.anchor.set(0.5, 0.5);
    this.freeSpinsCountLabel.x = 0;
    this.freeSpinsCountLabel.y = 0;

    this.freeSpinsAmountContainer.addChild(this.freeSpinsCountLabel);
  }

  initPage2() {
    this.content.goToFrame(2);
    this.titleText = this.content.getChildByName('freeSpins_finish_label1');
    this.title2Text = this.content.getChildByName('freeSpins_finish_label2');
    this.numbersAnimationContainer = this.content.getChildByName('numbersAnimationContainer');

    this.coinsContainer.visible = true;
    this.coinParticles.start();
    this.animateValue(EntryPoint.GameModel.freeSpins.totalWin, this.numbersAnimationContainer,
      {
        fontName: 'fb_font',
        fontSize: 180,
        anchor: {
          x: 0.5,
          y: 0.5,
        },
      });
    this._timeout = setTimeout(this._goToNextState.bind(this), 5000)

  }

  async initParticles() {
    await PIXI.Assets.load('coins')
    // const x = this.displayData.width / 2;
    // const y = this.content.height / 2;

    this.coinsContainer = new PIXI.Container();
    // this.coinsContainer.x = x;
    // this.coinsContainer.y = y;

    this.coinParticles = new ParticleSystem(this.coinsContainer, {
      animationType: animationTypes.COIN,
      animationName: 'coin'
    }, {
      ...particlesConfig.coinParticlesConfig,
      x0min: 0,
      x0max: 0,
      y0min: 0,
      y0max: 0,
      v0max: 1.4,
      angle0From: -Math.PI / 180 * 120,
      angle0To: -Math.PI / 180 * 60,
      count: 160
    });

    this.coinsAnimationContainer.addChild(this.coinsContainer);
    this.coinsContainer.visible = false;
  }

  animateValue(value, container, styles) {
    this.winAmountCounter = new WinAmountAnimated(container, styles);
    this.winAmountCounter.animate(0, value, 2000);
  }

  get isVisible() {
    return this._isVisible;
  }

  onKeyDown(e) {
    if (e.code === 'Space') {
      e.stopPropagation();
      e.preventDefault();
      if (this.winAmountCounter) this._skipAnimation()
    }
  }

  onClick() {
    if (this.winAmountCounter) this._skipAnimation();
  }

  _addDynamicListeners() {
    this.interactive = true;
    if (!OPWrapperService.config.skipBlocked) {
      if (!OPWrapperService.config.disableSpinBySpace) document.addEventListener('keydown', this.onKeyDown)
      this.off('pointertap', this._goToNextState);
      this.on('pointertap', this.onClick);
    }
  }

  _removeDynamicListeners() {
    this.interactive = false;
    document.removeEventListener('keydown', this.onKeyDown)
    if (!OPWrapperService.config.skipBlocked) {
      this.off('pointertap', this._goToNextState);
      this.off('pointertap', this.onClick);
    }
  }

  _goToNextState() {
    GlobalDispatcher.dispatch('freeSpinsWindowContainer:stopState')
  }

  _skipAnimation() {
    if (!OPWrapperService.config.skipBlocked) {
      this.off('pointertap', this.onClick)
      this.on('pointertap', this._goToNextState);
    }
    document.removeEventListener('keydown', this.onKeyDown)

    this.winAmountCounter.stop();
    this.winAmountCounter.set(EntryPoint.GameModel.freeSpins.totalWin);
    clearTimeout(this._timeout);
    this._timeout = setTimeout(this._goToNextState.bind(this), 2000)
  }
}
