import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";

export default class FreeSpinsInState extends BaseState {

    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    start() {
        EntryPoint.GameModel.isFreeSpinsInActiveState = true;
        super.start();
        EntryPoint.AutoPlaySettings.active = false;
        this.timeout = setTimeout(() => {
            this.complete();
        }, 5000);
    }

    onEnd() {
        super.onEnd();
        clearTimeout(this.timeout);
    }
}
