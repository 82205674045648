import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import SoundManager from 'Engine/soundManager/SoundManager';
import WinAmountAnimated from '../../winAmountAnimated/selfResizedWin';
import animationCreator from 'Engine/animations/animationCreator';
import BigWinController from './winControllers/bigWinController';
import { gsap } from 'gsap';
import animationTypes from '../../enums/animationTypes';
import { eSounds as eSounds } from '../../enums/eSounds';
import eBigWinTypes from '../../enums/eBigWinTypes';
import eEventTypes from '../../enums/eEventTypes';
import particlesConfig from 'Engine/particles/particleConfig';

import {
  eBigWinAnimationNames,
  eMegaWinAnimationNames,
  eEpicWinAnimationNames, eAnimationNames
} from '../../enums/eAnimationNames';
import { eWinAmountSizes } from '../../enums/eWinAmountSizes';
import ParticleSystem from 'Engine/particles/particleSystem';
import MegaWinController from './winControllers/megaWinController';
import EpicWinController from './winControllers/epicWinController';

export default class PopupWin extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.eventMode = 'static';
    this.winControllers = {};
    this.stack = [];
    this.cancelExecutionCallbacks = [];
    this.currentStep = null;
    this._isHiding = false;
    this._completeDelay = 1200;

    this.stepsData = {
      // [eBigWinTypes.EBWT_WIN]: {
      //   controller: SmallWinController,
      //   config: { animationName: null, sound: eSounds.SMALL_WIN, duration: 1000, counterOffset: 0 }
      // },
      [eBigWinTypes.EBWT_BIG_WIN]: {
        controller: BigWinController,
        delayed: true,
        config: {
          animationName: eBigWinAnimationNames,
          sound: eSounds.BIG_WIN,
          duration: 4000,
          counterOffset: 0,
          completeDelay: 1500
        }
      },
      [eBigWinTypes.EBWT_MEGA_WIN]: {
        delayed: true,
        controller: MegaWinController,
        config: {
          animationName: eMegaWinAnimationNames,
          sound: eSounds.MEGA_WIN,
          duration: 4000,
          counterOffset: 0,
          completeDelay: 1500
        }
      },
      [eBigWinTypes.EBWT_EPIC_WIN]: {
        delayed: true,
        controller: EpicWinController,
        config: {
          animationName: eEpicWinAnimationNames,
          sound: eSounds.EPIC_WIN,
          duration: 4500,
          counterOffset: 0,
          completeDelay: 500
        }
      },
    };

    this._execute = this._execute.bind(this);
    this._waitCompleteDelay = this._waitCompleteDelay.bind(this);
    this._init();
    this._initAnimationPromise = this._initAnimation();
    this._initWinControllers();
    this._addListeners();
  }

  async show() {
    this.stopMainBackgroundSound();
    this.startWinBackground();

    this._createStack();
    await this.checkInitializationWinControllers();
    this.visible = true;
    this.winContainer.visible = true;
    this.eventMode = 'static';
    this.alpha = 1;
    this._execute();
  }

  async checkInitializationWinControllers() {
    const promises = [];
    this.stack.forEach(controller => {
      if (controller.promise && !controller.promise.isFulfilled) promises.push(controller.promise);
    });
    if (promises.length) {
      window.OPPreloader.showDelayedPreloader();
      await Promise.all(promises);
      window.OPPreloader.hideDelayedPreloader();
    }
  }

  stopMainBackgroundSound() {
    const sound = EntryPoint.GameModel.freespinsEnabled ? eSounds.FREE_SPINS_BACK : eSounds.BACKGROUND;

    const backgroundMainGameSound = SoundManager.getSound(sound.name);
    gsap.to(backgroundMainGameSound, { volume: 0, duration: 0.3 })
  }

  playMainBackgroundSound() {
    const sound = EntryPoint.GameModel.freespinsEnabled ? eSounds.FREE_SPINS_BACK : eSounds.BACKGROUND;
    const backgroundMainGameSound = SoundManager.getSound(sound.name);

    gsap.fromTo(backgroundMainGameSound, { volume: 0 }, { volume: sound.volume, duration: 1.5 })
  }

  startWinBackground() {
    SoundManager.play(eSounds.BIG_WIN_BACK.name, eSounds.BIG_WIN_BACK.volume, true, eSounds.BIG_WIN_BACK.group);
  }

  stopWinBackground() {
    SoundManager.stop(eSounds.BIG_WIN_BACK.name);
  }

  showOverlay = (duration) => {
    // this.bgAnimation.state.setAnimation(0, 'sample_pop-up', true);
    // this.bgAnimation.visible = true;
    this.overlay.alpha = 1;
    this.overlay.visible = true;
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
    // gsap.killTweensOf(this.panel);
    // gsap.to(this.panel, {alpha: 0, duration});
  };

  _init() {
    this.visible = false;
    this.overlay = this.getChildByName('overlay');
    this.overlay.alpha = 1;
    this.overlay.visible = false;
    this.overlay.changeColor('0x000000');

    // this.bgAnimation = this.getChildByName('bgAnimation');

    this.wrapper = this.getChildByName('wrapper');
    // this.panel = this.wrapper.getChildByName('panel');
    this.winContainer = this.wrapper.getChildByName('numbersAnimationContainer');
    this.winContainer.visible = false;
    this.winAmountContainer = this.winContainer.getChildByName('amount');

    this.counter = new WinAmountAnimated(this.winAmountContainer, { fontSize: eWinAmountSizes.H.REGULAR });
    this.counter.decimals = EntryPoint.GameModel.currencyInfo.decimals;
    this._hideOverlay();
  }

  initParticles() {
    this.coinsContainer = new PIXI.Container();
    this.wrapper.addChildAt(this.coinsContainer, 0);
    this.coinsContainer.x = this.wrapper.parent.displayData.width / 2;
    // this.coinsContainer.y = window.OPWrapperService.ScaleManager.orientation === 'H' ? 600 : 100;
    this.coinsContainer.y = window.OPWrapperService.ScaleManager.orientation === 'H' ? 530 : 470;
    const config = {
      ...particlesConfig.coinParticlesConfig,
      x0min: -100,
      x0max: 100,
      y0min: 0,
      y0max: 0,
      v0max: 1.4 * 1.5,
      v0min: 2 * 1.5,
      scaleEnd: 1,
      angle0From: -Math.PI / 180 * 105,
      angle0To: -Math.PI / 180 * 75,
      lifetimeMin: 2000, // milliseconds
      lifetimeMax: 2500, // milliseconds
      count: 30
    };

    this.coinParticles = new ParticleSystem(this.coinsContainer, {
      animationType: animationTypes.COIN,
      animationName: eAnimationNames.EAN_COIN_FLIP
    }, config);
    this.coinParticles.stop();
    this.coinParticles2 = new ParticleSystem(this.coinsContainer, {
      animationType: animationTypes.COIN,
      animationName: eAnimationNames.EAN_COIN_FLIP
    }, config);
    this.coinParticles2.stop();
    this.coinParticles3 = new ParticleSystem(this.coinsContainer, {
      animationType: animationTypes.COIN,
      animationName: eAnimationNames.EAN_COIN_FLIP
    }, config);
    this.coinParticles3.stop();
    this.coinParticlesInited = true;
  }

  async _initAnimation() {
    const container = this.wrapper.getChildByName('animationContainer');

    this.animation = await animationCreator.createAnimationWithDelayedLoading(animationTypes.BIG_WIN);
    this.animation.visible = false;
    // this.animation.state.addListener(this.animationListener);
    container.addChild(this.animation);
    await PIXI.Assets.load(animationTypes.COIN);
    this.initParticles();
  }

  async _initWinControllers() {
    [this.stepsData].forEach(steps => {
      for (let key in steps) {
        if (this.winControllers.hasOwnProperty(key)) continue;

        this.winControllers[key] = new steps[key].controller({
          controllerPopup: this,
          config: steps[key].config,
          animation: this.animation,
          counter: this.counter,
          smallWinCounter: this.smallWinCounter,
          coefCounter: this.coefCounter,
          totalWinCounter: this.totalWinCounter,
          winAmountContainer: this.winAmountContainer,
          coinParticles: this.coinParticles,
          coinParticles2: this.coinParticles2,
          coinParticles3: this.coinParticles3,
        });

        if (steps[key].delayed) {
          this.winControllers[key].promise = new Promise(async (resolve) => {
            await this._initAnimationPromise;
            this.winControllers[key].animation = this.animation;
            this.winControllers[key].coinParticles = this.coinParticles;
            this.winControllers[key].coinParticles2 = this.coinParticles2;
            this.winControllers[key].coinParticles3 = this.coinParticles3;
            this.winControllers[key].promise.isFulfilled = true;
            resolve();
          });

        }

      }
    });
  }

  _addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_SHOW_WIN_STATE__START, this.show, this);
    GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__START, this._stop, this);
    // GlobalDispatcher.add(eEventTypes.EET_STOP_WIN_STATE__END, this._hide, this);
    this.on('pointertap', () => {
      if (this._startWaitPoint) {
        this._startWaitPoint = -this.currentStep.completeDelay || -this._completeDelay;
        this._hide();
        return;
      }
      StatesManager.goToNextState();
    });
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onGameResized,
      this
    );
  }

  _onGameResized(data) {
    if (data.isLandscape) {
      this.wrapper.anchorDefault();
      this.wrapper.scale.set(this.wrapper.displayData.scaleX);

      this.winAmountContainer.y = 50;
      this.winAmountContainer.scale.set(1);
      // if (this.coinsContainer) this.coinsContainer.y = 600;

    } else if (data.isMobile && data.isPortrait) {
      this.wrapper.anchorDefault();
      this.wrapper.y = this.wrapper.displayData.y / 2 + 100;
      // this.wrapper.x = this.displayData.width / 2- 935;
      this.wrapper.x = this.displayData.width / 2 - 695;
      this.wrapper.scale.set(0.6);
      // if (this.coinsContainer) this.coinsContainer.y = 400;
      this.winAmountContainer.scale.set(1.6);
      this.winAmountContainer.y = 100;
    }

  }

  _createStack() {
    let stackSource = Object.keys(this.stepsData);

    for (let key of stackSource) {
      if (EntryPoint.GameModel.totalWin / EntryPoint.GameModel.bet >= EntryPoint.GameModel.winRankCoef[key]) {
        const controller = this.winControllers[key];
        const cancelExecutionCallback = controller.once('complete', this._execute);
        this.cancelExecutionCallbacks.push(cancelExecutionCallback);

        controller.startValue = EntryPoint.GameModel.winRankCoef[key] * EntryPoint.GameModel.bet;
        controller.endValue = EntryPoint.GameModel.totalWin;

        if (this.stack.length) this.stack[this.stack.length - 1].endValue = controller.startValue;

        this.stack.push(controller);
      }
    }
  }

  _execute = () => {
    if (!this.stack.length) {
      this._complete();
      return;
    }

    this.currentStep = this.stack.shift();
    this.currentStep.start();
  };

  _complete() {
    GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP);
  }

  _stop() {
    this.counter.stop();
    this.counter.set(EntryPoint.GameModel.totalWin);

    this._cancelScheduledExecutions();
    this.currentStep.stop();

    if (this.stack.length) {
      this.stack.forEach(controller => controller.onSkip());

      this.currentStep = this.stack[this.stack.length - 1];
      this.currentStep.setIdle();
      this.stack = [];
    }

    this._waitCompleteDelay();
  }

  _cancelScheduledExecutions() {
    this.cancelExecutionCallbacks.forEach(cancelExecution => cancelExecution());
    this.cancelExecutionCallbacks = [];
  }

  _waitCompleteDelay = (timestamp = 0) => {
    if (!this._startWaitPoint) this._startWaitPoint = timestamp;
    if (timestamp - this._startWaitPoint >= (this.currentStep.completeDelay || this._completeDelay)) {
      this._startWaitPoint = null;
      this._tryToHide();
      // GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED);
      return;
    }

    requestAnimationFrame(this._waitCompleteDelay);
  };

  _tryToHide() {
    if (this._isHiding || this.visible === false) return;
    this._hide();
  }

  _hide() {
    this.eventMode = 'none';
    this._isHiding = true;
    this.stopWinBackground();
    this.playMainBackgroundSound();

    gsap.to(this, {
      alpha: 0, duration: 0.2, onComplete: () => {
        this.visible = false;
        this._isHiding = false;
        if (this.animation) this.animation.visible = false;
        if (this.coinParticlesInited) {
          this.coinParticles.stop();
          this.coinParticles.visible = false;
          this.coinParticles2.stop();
          this.coinParticles2.visible = false;
          this.coinParticles3.stop();
          this.coinParticles3.visible = false;
        }
        this.winContainer.visible = false;
        this._hideOverlay();
        Object.values(this.winControllers).forEach(controller => controller.cleanup());
        GlobalDispatcher.dispatch(eEventTypes.EET_BIG_WIN__SKIP_COMPLETED);
        // GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
      }
    })

  }

  _hideOverlay() {
    // if (this.bgAnimation) {
    //   this.bgAnimation.visible = false;
    //   this.bgAnimation.lastTime = null;
    //   this.bgAnimation.state.clearTrack(0);
    //   this.bgAnimation.skeleton.setToSetupPose();
    // }
    this.overlay.hide();
    // gsap.killTweensOf(this.panel);
    // this.panel.alpha = 1;
  }
}

