import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import SoundManager from "Engine/soundManager/SoundManager";
import EntryPoint from 'Engine/EntryPoint';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { eSounds } from '../enums/eSounds';

export default class BaseCoinValueContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.incativeTintColor = 0xCCCCCC;

    this.valuesRange = [];
    this.valueText = null;
    this.currentValue = 0;

    this._enabled = false;

    this.init();
    this.addActions();
  }

  setRange(valuesRange) {
    this.valuesRange = valuesRange;
  }

  init() {
    this.decreaseButton = this.getChildByName('decreaseButton');
    this.increaseButton = this.getChildByName('increaseButton');
    makeHitArea(this.decreaseButton);
    makeHitArea(this.increaseButton);

    this.titleText = this.getChildByName('ui_coinValue_label');
    this.valueText = this.getChildByName('valueText');

    if (this.titleText) {
      this.titleText.style = {...this.titleText.style, ...shadowText};
    }

    this.disableButtons();
  }

  addActions() {
    this.decreaseButton.on('pointertap', this.onClickDecreaseButton, this);
    this.increaseButton.on('pointertap', this.onClickIncreaseButton, this);
  }

  onClickDecreaseButton(data) {
    GlobalDispatcher.dispatch(eBaseEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex - 1);
  }

  onClickIncreaseButton(data) {
    GlobalDispatcher.dispatch(eBaseEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex + 1);
  }

  onButtonClick(data) {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
    GlobalDispatcher.dispatch('ui:changeBet', data);
  }

  setValue(value) {
    this.currentValue = value;
    this.valueText.text = MoneyFormat.format(this.currentValue, false, false);
    this.checkButtonsToEnable();
  }

  checkButtonsToEnable() {
    this.decreaseButton.enabled = this.valuesRange.indexOf(this.currentValue) > 0;
    this.increaseButton.enabled = this.valuesRange.indexOf(this.currentValue) < this.valuesRange.length - 1;
  }

  disableButtons() {
    this.decreaseButton.enabled = false;
    this.increaseButton.enabled = false;
  }

  enableButtons() {
    this.checkButtonsToEnable();
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value) {
    this._enabled = value;
    this.interactive = value;
    /*this.children.forEach((child)=>{
        child.tint = this._enabled ? 16777215 : this.incativeTintColor;
    });*/

    if(!this._enabled) {
      this.disableButtons();
    } else {
      this.enableButtons();
    }
  }
}
