export default {
  SYMBOLS: 'symbols',
  BIG_WIN: 'bigWin_spine',
  ANTISIPATOR: 'neat_win',
  PURCHASE_BUTTON: 'purchase_button',
  PURCHASE: 'purchase_animation',
  COIN: 'coins',
  LINES_SPINE: 'lines_spine',
  FREE_SPINS_WINDOW: 'freeSpinsWindow_spine',
  MULTIPLIER: 'multiplier',
  BACKGROUND: 'background'
}
