import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';

export default class FreeSpinsTopContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.init();
        this.visible = false;

        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
    }

    init() {
        this.countContainer = this.getChildByName('countContainer');
        this.symbol = this.getChildByName('symbol').getChildAt(1);
    }

    onGameResized(data) {
        this.initFrame(data.canvasWidth, data.canvasHeight);
        if (!this.visible) return;

        this.countContainer.setValue((EntryPoint.GameModel.freeSpins.played) + '/' + EntryPoint.GameModel.freeSpins.total);
        this.symbol.changeSymbol(EntryPoint.GameModel.freeSpins.specialSymbol);
    }

    initFrame(width, height) {
        if (EntryPoint.mobile) {
            let frameIndex = height > width ? 2 : 1;
            this.goToFrame(frameIndex);
            this.init();
        }
    }

    addListeners() {
        GlobalDispatcher.add('stateStart:requestRollingState', this.onRequestRollingStateStart, this);
    }

    removeListeners() {
        GlobalDispatcher.remove('stateStart:requestRollingState', this.onRequestRollingStateStart, this);
    }

    onRequestRollingStateStart() {
        this.countContainer.setValue((EntryPoint.GameModel.freeSpins.played + 1 || 0) + '/' + EntryPoint.GameModel.freeSpins.total);
    }

    destroy(options) {
        GlobalDispatcher.remove('stateStart:requestRollingState', this.onRequestRollingStateStart, this);
        window.OPWrapperService.eventManager.remove(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
        super.destroy(options);
    }

    show() {
        this.addListeners();
        this.countContainer.setValue((EntryPoint.GameModel.freeSpins.played) + '/' + EntryPoint.GameModel.freeSpins.total);
        this.symbol.changeSymbol(EntryPoint.GameModel.freeSpins.specialSymbol);
        this.visible = true;
    }


    hide() {
        this.removeListeners();
        this.visible = false;
        this.countContainer.setValue('0 / 0');
    }
}
