import EntryPoint from 'Engine/EntryPoint';
import BaseSlotSymbol from 'Engine/containers/BaseSlotSymbol';
import { gsap } from 'gsap'
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { getRandom } from 'Engine/utils/getRandom';
import GameModel from '../model/GameModel';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class Symbol extends BaseSlotSymbol {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.createTweens();

    this.incativeTintColor = 0x444444;
    this.availableLabelCoeffs = EntryPoint.configData.reels.availableLabelCoeffs[0];
  }

  init() {
    super.init();
    this.frame = this.getChildAt(0);
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('reelsContainer:updateLabelCoeffs', this.updateCoeffs, this);
    GlobalDispatcher.add('stateStart:requestRollingState', this.resetCoeffs, this);
  };

  updateCoeffs(event) {
    this.availableLabelCoeffs = event.params;
  }

  resetCoeffs() {
    this.availableLabelCoeffs = EntryPoint.configData.reels.availableLabelCoeffs[0];
  }

  createTweens() {
    this.animationTween = gsap.to(this.scale, { x: 0.9, y: 0.9, yoyo: true, duration: 0.1, repeat: -1, paused: true });
    this.showTween = gsap.to(this, {
      alpha: 1,
      paused: true,
      duration: 0.1,
      onComplete: this.onShowCompete.bind(this)
    });
    this.fadeTween = gsap.to(this, { alpha: 0, duration: 0.1, paused: true });
  }

  changeSymbol(id, reel) {
    super.changeSymbol(id, reel);
    this.stopAnimation();
    // this.frame.id = this.id;
    this.checkLabel();
  }

  checkLabel() {
    this.removeLabel();
    if (this.id !== this.config.specialSymbolId) return;
    this.showLabel();
  }

  showLabel(label) {

    if (!label) {
      const coeffIndex = getRandom(0, this.availableLabelCoeffs.length - 1);
      const coeff = this.availableLabelCoeffs[coeffIndex];
      if (!coeff) return console.warn('Can\'t get coeff');
      label = MoneyFormat.format(coeff * EntryPoint.GameModel.bet, GameModel.currencyInfo.decimals);
    }

    this.createLabel(label);
  }

  createLabel(label) {
    this.removeLabel();
    this.winLabel = new PIXI.BitmapText(`${label}`,
      { fontName: 'apple_font', fontSize: 30 }
    );

    this.winLabel.anchor.set(0.5, 0.5);
    this.winLabel.x = EntryPoint.configData.symbols.width / 2;
    this.winLabel.y = EntryPoint.configData.symbols.height / 2;

    this.addChild(this.winLabel);
  }

  removeLabel() {
    if (!this.winLabel) return;
    this.removeChild(this.winLabel);
    this.winLabel.destroy({ children: true });
    this.winLabel = null;
  }

  iterateOverSymbols(symbols) {
    this.iterateSymbols = symbols;
    this.alpha = 0;

    this.animateSymbolChange(this.iterateSymbols.pop());

    this.iterateInterval = setInterval(() => {
      this.animateSymbolChange(this.iterateSymbols.pop());

      if (!this.iterateSymbols.length) {
        this.clearInterval();
      }
    }, 200)
  }


  animateSymbolChange(symbol) {
    this.fadeTween.kill();
    this.showTween.kill();

    this.showTween.play(0);
    this.changeSymbol(symbol);
  }

  showFrame() {
    this.frame.goToFrame(2);
  }

  hideFrame() {
    this.frame.goToFrame(1);
  }

  startAnimation(min = false) {
    this.image = this.getChildAt(1);
    this.image.visible = false;
  }

  stopAnimation() {
    this.image.visible = true;
    this.frame.goToFrame(1);
    // this.frame.visible = true;
  }

  setBlur(value) {
    // super.setBlur(value);
    // this.frame.setBlur();
    /*if (value && this.id < 6) {
       this.setActive(EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol);
    } else {
        this.setActive(true);
    }*/
    this.setActive(true);
  }

  setActive(value) {
    // this.frame.setActive(value);
    let image = this.getChildAt(1);
    image.tint = value ? 16777215 : this.incativeTintColor;

    if (this.winLabel) {
      this.winLabel.tint = value ? 16777215 : this.incativeTintColor;
    }
  }
}
