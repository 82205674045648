import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default class BaseSymbolsAnimationsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.animations = [];

    this.init();
    this.addListeners();
  }

  init() {

  }

  addListeners() {
    GlobalDispatcher.add('winningsController:slowWin', this.showWinningSymbols, this);
  }

  showWinningSymbols(data) {
    this.removeAnimations();
    data.params.path.forEach((pathData) => {
      this.addAnimation(pathData);
    });
  }

  addAnimation(pathData) {
    /*let id = EntryPoint.GameModel.reels[pathData[0]][pathData[1]];
    let animation = new SymbolAnimation(id);
    animation.x = pathData[0] * 178;
    animation.y = pathData[1] * 178;
    this.addChild(animation);
    this.animations.push(animation);*/
  }

  removeAnimations() {
    this.animations.forEach((data)=>{
      data.animation.stop();
    });
    this.animations = [];
  }
}
