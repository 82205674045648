import FlashLib from 'flashlib_onlyplay';
import animationTypes from '../enums/animationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import EntryPoint from 'Engine/EntryPoint';
import { gsap, Linear } from 'gsap';

export default class BackgroundContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.active = false;

    if (!EntryPoint.mobile) {
      this.initAnimation();
    }

    this.init();
    this.createGsap();
    this.addListeners();
  }

  init() {
    this.freeSpinsBackground = this.getChildByName('freeSpinsBackground');
    this.mainBackground = this.getChildByName('mainBackground');

    if (!PIXI.isMobile.any) {
      this.freeSpinsBackground.goToFrame(2);
      this.mainBackground.goToFrame(2);
    }

    this._updateBackgroundVisibility();
  }

  createGsap() {
    this.showTween = gsap.fromTo(this.freeSpinsBackground, { alpha: 0 }, {
      alpha: 1,
      duration: 1,
      ease: Linear.easeNone,
      paused: true,
    });

    this.hideTween = gsap.fromTo(this.freeSpinsBackground, { alpha: 1 }, {
      alpha: 0,
      duration: 1,
      ease: Linear.easeNone,
      paused: true,
      onComplete: this._hideFreeSpinsBack.bind(this),
    });
  }

  initAnimation() {
    this.animationContainer = this.getChildByName('animationContainer');
    this.animation = animationCreator.createAnimation(animationTypes.BACKGROUND);

    this.animation.state.setAnimation(0, 'bg_animation', true);
    this.animationContainer.addChild(this.animation);
  }

  showFreeSpinsBack() {
    this.active = true;
    this._updateBackgroundVisibility();
    this.showTween.play(0);
  }

  hideFreeSpinsBack() {
    this.mainBackground.visible = true;
    this.hideTween.play(0);
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this,
    );
  }

  onGameResized(data) {
    const newFrameIndex = data.isLandscape ? 2 : 1;

    if (newFrameIndex !== this.currentFrameIndex) {
      this._setFrameIndexAndInitGameObjects(newFrameIndex);
    }
  }

  _updateBackgroundVisibility() {
    this.mainBackground.visible = !this.active;
    this.freeSpinsBackground.visible = this.active;
  }

  _hideFreeSpinsBack() {
    this.active = false;
    this._updateBackgroundVisibility();
  }

  _setFrameIndexAndInitGameObjects(frameIndex) {
    this.goToFrame(frameIndex);
    this.init();
    this.createGsap();
  }
}
