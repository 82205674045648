import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import SoundManager from 'Engine/soundManager/SoundManager';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import EntryPoint from 'Engine/EntryPoint';
import { eSounds } from './enums/eSounds';
import './style.css';


export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.linesContainer = null;
    this.uiContainer = null;
    this.winningContainer = null;
    this.currentOrientation = null;

    this.init();
    this.addListeners();
  }

  onGameResized(data) {
    this.currentOrientation = data.orientation;
    const isMobile = EntryPoint.mobile;
    const isVertical = this.currentOrientation === 'V';
    const displayData = this.slot_container.displayData;

    if (isMobile && isVertical) {
      this.slot_container.pivot.x = 0;
      this.slot_container.x = displayData.x;
      this.slot_container.y = displayData.y;
      this.slot_container.scale.set(displayData.scaleX);
    } else {
      const widthOffset = isMobile ? 0.26 : 0.234;
      const heightOffset = isMobile ? 0.092 : 0.094;

      this.slot_container.x = displayData.x - displayData.width * widthOffset;
      this.slot_container.y = displayData.y - displayData.height * heightOffset;
      this.slot_container.scale.set(1);
    }

    this.changeRoof();
  }

  init() {
    this.winningsController = new BaseSlotWinningsController();

    this.slot_container = this.getChildByName('slot_container');
    this.roof = this.slot_container.getChildByName('Roof');
    this.freeSpinsTopContainer = this.slot_container.getChildByName('FreeSpinsTopContainer');
    this.respinButtonsContainer = this.slot_container.getChildByName('RespinButtonsContainer');
    this.respinInReelInfoContainer = this.slot_container.getChildByName('RespinInReelInfoContainer');
    this.reelsContainer = this.slot_container.getChildByName('ReelsContainer');
    this.symbolsAnimationsContainer = this.slot_container.getChildByName('SymbolsAnimationsContainer');
    this.linesContainer = this.slot_container.getChildByName('LinesContainer');
    this.lineWinningContainer = this.slot_container.getChildByName('LineWinningContainer');
    this.backgroundContainer = this.getChildByName('BackgroundContainer');
    this.freeSpinsWindowContainer = this.getChildByName('FreeSpinsWindowContainer');
    this.purchaseContainer = this.getChildByName('PurchaseContainer');
  }

  playBGSound() {
    if (EntryPoint.GameModel.isFreeSpinsMode) return;
    SoundManager.play(eSounds.BACKGROUND.name, eSounds.BACKGROUND.volume, true, 'music');
  }

  addListeners() {
    GlobalDispatcher.add('model:linesChanged', this.onLinesChanged, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add('stateEnd:initState', this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    this.playBGSound();
    if (!EntryPoint.GameModel.isFreeSpinsMode) return;
    this.freeSpinsTopContainer.show();
    this.changeRoof();
    this.backgroundContainer.showFreeSpinsBack();
    this.respinButtonsContainer.setActive(false);
    this.respinInReelInfoContainer.setActive(false);
    SoundManager.pause(eSounds.BACKGROUND.name);
    SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
  }

  changeRoof() {
    if (EntryPoint.GameModel.isFreeSpinsMode) {
      this.roof.goToFrame(2);
    } else {
      const roofFrame = this.currentOrientation === 'V' ? 3 : 1;
      this.roof.goToFrame(roofFrame);
    }
  }

  onStateChanged(data) {
    switch (data.params) {
      case 'initState':

        break;
      case 'showStartScreenState':
        this.changeRoof();
        break;
      case 'waitUserInteractionState':
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        this.roof.goToFrame(2);
        break;
      case 'idleState':
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
          this.changeRoof();
          this.freeSpinsTopContainer.hide();
          this.respinButtonsContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
          this.respinInReelInfoContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
        }
        this.respinButtonsContainer.setData(EntryPoint.GameModel.respinCost);
        this.respinInReelInfoContainer.setData(EntryPoint.GameModel.respinCost);
        break;
      case 'requestRespinRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.startRespin();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'stopRespinRollingState':
        this.reelsContainer.stopRespin(GameModel.reels);
        break;
      case 'requestRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.start();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'stopRollingState':
        this.reelsContainer.stop(GameModel.reels);
        break;
      case 'showBigWinState':
        this.lineWinningContainer.show(2);
        break;
      case 'showLinesWinState':
        this.lineWinningContainer.show(1);
        break;
      case 'showWinningsState':
        this.winningsController.start();
        this.reelsContainer.setActive(false);
        break;
      case 'showBonusState':
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations(true);
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(false);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        break;
      case 'expandSymbolsState':
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(false);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        break;
      case 'showExpandedWinState':
        SoundManager.play(eSounds.SMALL_WIN.name, eSounds.SMALL_WIN.volume, false, 'winning');
        break;
      case 'FreespinsAddState':
        SoundManager.pause(eSounds.BACKGROUND.name);
        const freeSpinsSound = SoundManager.getSound(eSounds.FREE_SPINS_BACK.name);
        if (!freeSpinsSound.isPlaying) {
          SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
        }
        this.freeSpinsWindowContainer.show(1);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.roof.goToFrame(2);
        this.freeSpinsTopContainer.show();
        this.backgroundContainer.showFreeSpinsBack();
        GlobalDispatcher.dispatch('ui:changeAutoPlay', true);
        break;
      case 'FreespinsOutState':
        SoundManager.resume(eSounds.BACKGROUND.name);
        SoundManager.stop(eSounds.FREE_SPINS_BACK.name);
        this.freeSpinsWindowContainer.show(2);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.changeRoof();
        this.freeSpinsTopContainer.hide();
        this.backgroundContainer.hideFreeSpinsBack();
        GlobalDispatcher.dispatch('ui:changeAutoPlay', false);
        break;
    }
  }
}
