import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class StopRollingState extends BaseState {
  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('reelsContainer:reelsStopped', this.onReelsStopped, this);
  }

  onReelsStopped() {
    if (this.active) {
      this.complete();
    }
  }
}
