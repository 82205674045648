import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import FlashLib from 'flashlib_onlyplay';
import eSymbolIndex from '../enums/eSymbolIndex';
import eEventTypes from '../enums/eEventTypes';
// import ControllerFeatures from '../slot/features/ControllerFeatures';
import eAnimationTypes from '../enums/animationTypes';
import { eAnimationNames } from '../enums/eAnimationNames';
import { eSounds } from '../enums/eSounds';

export default class ReelsContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.labelPositions = [];
    this._freespinsTriggerIds = [eSymbolIndex.ESI_WILD_BOOK];
    this.bonusSymbolsCount = 0;

    this.init();
    // this.initFeaturesController(EntryPoint.movieClipAccessor.getMovieClip('topContainer'));
    this.addListeners();
    this.initReels();
  }

  init() {
    this._stopSoundPlayed = false;
    this._scatterSoundPlayed = false;
    this.hasScatter = false;
    this.wrapper = this.getChildByName('wrapper');

    this.ControllerReels = this.wrapper.getChildByName('controllerReels');
    this.Antisipators = this.wrapper.getChildByName('antisipator');
    this.Antisipators.minCountForStartAnimation = 2;
    this.Antisipators.maxCountForStartAnimation = 5;
    this.Antisipators.mainSoundType = eSounds.ANTISIPATOR.name;
    this.Antisipators.mainSoundVolume = eSounds.ANTISIPATOR.volume;
    this.Antisipators.mainSoundGroup = eSounds.ANTISIPATOR.group;
    this.Antisipators.animationType = eAnimationTypes.ANTISIPATOR;
    this.Antisipators.animationName = eAnimationNames.EAN_ANTISIPATOR;
    this.Antisipators.triggerSymbolsId = [eSymbolIndex.ESI_WILD_BOOK];
    this.Antisipators.turnOnIdsOnFreespins = [eSymbolIndex.ESI_WILD_BOOK];
    this.Antisipators.init();

    this._drawMask();
  }

  initReels() {
    this.ControllerReels.hideBottomSymbols = false;
    this.ControllerReels.init(EntryPoint.configData.reels.availableReelsNormal);
  }

  // initFeaturesController(topContainer) {
  //   this.ControllerFeatures = new ControllerFeatures({ reelsContainer: this, controllerReels: this.ControllerReels, topContainer});
  // }

  addListeners() {
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onAllReelsStopped.bind(this);
    this.ControllerReels.onCycleDataSymbol = this.showBonusLabels.bind(this);
    GlobalDispatcher.add('symbolAnimation:start', this.onSymbolAnimationStart, this);
    GlobalDispatcher.add('symbolAnimation:end', this.onSymbolAnimationComplete, this);
    // this.ControllerReels.onCycleAvailableSymbol = this.onCycleAvailableSymbol.bind(this);
    // this.ControllerReels.onCycleDataSymbol = this.onCycleDataSymbol.bind(this);
    // GlobalDispatcher.add(eEventTypes.EET_FREESPIN_IN_STATE__START, this.onStartBonusGameInState, this);
    GlobalDispatcher.add('respinInReelInfo:show', this.onRespinInReelInfoShow, this);
    GlobalDispatcher.add('respinInReelInfo:hide', this.onRespinInReelInfoHide, this);
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.stopAnimations();
  }

  onHitBar(reelIndex) {
    this.onReelStopped(reelIndex);
    this.Antisipators.tryStopAntisipatorOnReel(reelIndex);
    this.Antisipators.tryStartAntisipatorForReel(reelIndex + 1);

    this.playStopSound();
  }

  // onCycleAvailableSymbol(...args) {
  //   console.log('onCycleAvailableSymbol')
  //   // this.ControllerFeatures.onCycleAvailableSymbol(...args);
  // }
  //
  // onCycleDataSymbol(...args) {
  //   console.log('onCycleDataSymbol')
  //   // this.ControllerFeatures.onCycleDataSymbol(...args);
  // }

  playStopSound() {
    if (!this._stopSoundPlayed) {
      if (!EntryPoint.GameSettings.quickSpin) {
        SoundManager.play(eSounds.REEL_STOP.name, eSounds.REEL_STOP.volume + 0.2 * Math.random(), false, eSounds.REEL_STOP.group);
      }
      this._stopSoundPlayed = EntryPoint.GameSettings.quickStop;
    }

    if (this.hasScatter) {
      this.hasScatter = false;
      if (!this._scatterSoundPlayed) {
        if (!EntryPoint.GameSettings.quickSpin) {
          SoundManager.play(eSounds.SCATTER_STOP.name, eSounds.SCATTER_STOP.volume + 0.2 * Math.random(), false, eSounds.SCATTER_STOP.group);
        }
        this._scatterSoundPlayed = EntryPoint.GameSettings.quickStop;
      }
    }
  }

  onRespinInReelInfoShow(event) {
    //this.reels[event.params - 1].setActive(false);
  }

  onRespinInReelInfoHide(event) {
    //this.reels[event.params - 1].setActive(EntryPoint.GameModel.totalWin <= 0);
  }

  hideWinningSymbols() {
    this.stopAnimations();
  }

  onAllReelsStopped() {
    if (EntryPoint.GameSettings.quickSpin) {
      SoundManager.play(eSounds.REEL_STOP.name, eSounds.REEL_STOP.volume + 0.2 * Math.random(), false, eSounds.REEL_STOP.group);
    }
    this.ControllerReels.excludedReels = [];
    GlobalDispatcher.dispatch(eEventTypes.EBET_REELS_STOPPED);
  }

  onSymbolAnimationStart(event) {
    let position = event.params.position;
    this.ControllerReels.reels[position[0]].symbols[position[1] + this.ControllerReels.topSimbolsCount].startAnimation();
  }

  onSymbolAnimationComplete(event) {
    let position = event.params;
    this.ControllerReels.reels[position[0]].symbols[position[1] + this.ControllerReels.topSimbolsCount].stopAnimation();
  }

  showBonusLabels(symbol, reelId) {
    this.playStopSound();
    if (!EntryPoint.GameModel.freeSpinsInActiveState || !EntryPoint.GameModel.freeSpins.bonusGameWins || symbol.id !== 12) return;

    const positionsOnReel = [];

    Object.keys(EntryPoint.GameModel.freeSpins.bonusGameWins).forEach(key => {
      if (Number(key[0]) !== reelId) return;

      const position = key.split('');
      this.labelPositions.push(position);
      positionsOnReel.push(position);
    });

    positionsOnReel.forEach(pos => {
      symbol.showLabel(EntryPoint.GameModel.freeSpins.bonusGameWins[`${reelId}${pos[1]}`]);
    });

    this.bonusSymbolsCount += positionsOnReel.length;
    this.updateSymbolsLabels();
  }


  updateSymbolsLabels() {
    GlobalDispatcher.dispatch('reelsContainer:updateLabelCoeffs', EntryPoint.configData.reels.availableLabelCoeffs[this.bonusSymbolsCount]);
  }

  start(isRespin) {
    this.bonusSymbolsCount = 0;
    const config = this._getReelsStartConfig();
    Object.assign(this.ControllerReels, config);

    if (isRespin) {
      const prevStartIndex = this.Antisipators.startsFromReelIndex;
      const prevEndIndex = this.Antisipators.endsOnReelIndex;

      this.Antisipators.startsFromReelIndex = EntryPoint.GameModel.respinReelIndex;
      this.Antisipators.endsOnReelIndex = EntryPoint.GameModel.respinReelIndex;
      this.Antisipators.checkAntisipators(EntryPoint.GameModel.reels);
      this.Antisipators.startsFromReelIndex = prevStartIndex;
      this.Antisipators.endsOnReelIndex = prevEndIndex;

      this.Antisipators.tryStartAntisipatorForReel(EntryPoint.GameModel.respinReelIndex);
    }

    this.ControllerReels.start();
  }

  stop(data, isRespin) {
    this.data = data;
    const config = this._getReelsStopConfig();

    let symbolsCountForStop;
    if (isRespin) {
      symbolsCountForStop = config.hasOwnProperty('symbolsCountForStop') ? config.symbolsCountForStop : this.ControllerReels.symbolsCountForStop;
    } else {
      symbolsCountForStop = this._getSymbolsCountForStop(data, config);
    }

    Object.assign(this.ControllerReels, config, { symbolsCountForStop });
    this.ControllerReels.stop(this.data);
  }

  // showWin(data) {
  //   this.ControllerFeatures.showWin(data);
  // }

  startRespin() {
    this.ControllerReels.excludedReels = this.ControllerReels.reels.filter(reel => reel.id !== EntryPoint.GameModel.respinReelIndex).map(reel => reel.id);
    this.start(true);
  }

  stopRespin(data) {
    this.stop(data, true);
  }

  stopAnimations() {
    this.ControllerReels.reels.forEach(reel => reel.symbols.forEach(symbol => symbol.stopAnimation()));
  }

  onReelStopped(index) {
    const symbols = this.ControllerReels.reels[index].symbols;
    for (let i = this.ControllerReels.topSimbolsCount; i <= symbols.length - 1 - this.ControllerReels.bottomSimbolsCount; i++) {
      const symbol = symbols[i];
      if (this._freespinsTriggerIds.includes(symbol.id)) {
        this.hasScatter = true;
        symbol.startAnimation();
      }
    }
    GlobalDispatcher.dispatch('reel:stopped', index);
  }

  _getSymbolsCountForStop(data, config) {
    let currentValues = config.hasOwnProperty('symbolsCountForStop') ? config.symbolsCountForStop : this.ControllerReels.symbolsCountForStop;
    if (!EntryPoint.GameSettings.quickStop) this.Antisipators.checkAntisipators(data);
    if (!this.Antisipators.involvedReels.length) return currentValues;

    let antisipatorDiff = 0;
    let antisipatorDiffStep = 40;

    const newValues = [];
    for (let i = 0; i < this.ControllerReels.reels.length; i++) {
      let value = this.ControllerReels.getConfigValue(currentValues, i);
      if (this.Antisipators.involvedReels.includes(i)) {
        antisipatorDiff += antisipatorDiffStep;
      }
      newValues.push(value + antisipatorDiff);
    }

    return newValues;
  }


  _getReelsConfig() {
    let config = EntryPoint.configData.reels.config;
    return EntryPoint.GameModel.freespinsEnabled ? config.freeSpins : config.regularSpins;
  }

  _getReelsStopConfig() {
    const config = this._getReelsConfig();
    return (EntryPoint.GameSettings.quickStop || EntryPoint.GameSettings.quickSpin) ? config.quick.stop : config.default.stop;
  }

  _getReelsStartConfig() {
    const config = this._getReelsConfig();
    return EntryPoint.GameSettings.quickSpin ? config.quick.start : config.default.start;
  }

  setActive(value) {
    this.ControllerReels.reels.forEach(reel => {
      reel.children.forEach((symbol) => {
        symbol.setActive(value);
      });
    })
  }

  _drawMask() {
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    // mask.drawPolygon([65,0, 1168,0, 1178,16, 1232,47, 1232,763, 1178,796, 1168,815, 65,815, 55,796, 0,763, 0,46, 54,16]);
    mask.drawRect(0, 230, 1350, 740);
    mask.endFill(); //1222,779,

    this.addChild(mask);
    this.ControllerReels.mask = mask;
  }
}
