import Animation from "Engine/base/animation/Animation";
import {gsap, Linear} from "gsap";
import BaseSlotLine from 'Engine/base/lines/BaseSlotLine';
import animationTypes from '../enums/animationTypes';

export default class WinLineAnimated extends BaseSlotLine {
    constructor(data, displayData) {
        super(data, displayData);
        this.positions = [
            {x: 555, y: -110},
            {x: 555, y: 330},
            {x: 555, y: 110},
            {x: 555, y: 330},
            {x: 555, y: 335},
            {x: 555, y: 110},
            {x: 555, y: 115},
            {x: 555, y: 330},
            {x: 555, y: 335},
            {x: 555, y: 335},
        ];

        this.createTweens();
    }

    init() {
        super.init();
        this.animation = new Animation(animationTypes.LINES_SPINE);
        this.addChild(this.animation);
    }

    createTweens() {
        this.tween = gsap.fromTo(this, {alpha:0}, {alpha:1, ease:Linear.easeNone,duration: 1, repeat:1, yoyo:true, paused:true});    }

    show(winning) {
        super.show();
        this.goToFrame(1);

        this.animation.x = this.positions[this.id].x;
        this.animation.y = this.positions[this.id].y;
        this.animation.play(true, `${this.id + 1}`);
        // this.tween.play(0);
        // this.play(false, false, 24);
    }

    hide() {
        this.animation.stop();
        super.hide();
        //this.tween.pause();
        //this.stop();
    }
}
