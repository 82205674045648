import BaseState from "Engine/base/states/BaseState";
import EntryPoint from 'Engine/EntryPoint';

export default class FreeSpinsOutState extends BaseState {

    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    start() {
        super.start();
        this.timeout = setTimeout(()=>{
            this.complete();
        }, 5000);
    }

    complete() {
        EntryPoint.GameModel.isFreeSpinsInActiveState = false;
        super.complete();
    }

    onEnd() {
        super.onEnd();
        clearTimeout(this.timeout);
    }
}
