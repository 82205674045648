import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import XXXDataParser from './XXXDataParser';
import ErrorManager from 'Engine/ErrorManager';
import EntryPoint from 'Engine/EntryPoint';
import UrlSettingsManager from '../settings/UrlSettingsManager';
import Utils from 'Engine/Utils';
import * as queryString from 'querystring';

export default class XXXData {
  constructor() {
    this.adress = window.location.origin;
    this._version = '1.1';

    this._tryGetSessionForDev();
    this.sss = 0;
    // this.init();

    // this.sid = UrlSettingsManager.sid;
    // this.gid = UrlSettingsManager.gid;
    this.sid = Utils.getUrlParam('sid');
    this.gid = Utils.getUrlParam('gid');

    this.cheatsParams = ['force_reels', 'force_jackpot', 'skip_freespins']; //?
  }

  async getSession() {
    try {
      const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=fruitybook&no_redirect&no_op_launcher', {
        method: 'GET',
      });
      const response = await raw.json();
      if (response.url) {
        const searchParams = response.url.split('?')[1];
        window.location = `${window.location.origin}?${searchParams}`;
      }
    } catch (e) {
      console.error(e);
    }
  }

  // init() {
  //   this.gameXHR = new XMLHttpRequest();
  //   this.gameXHR.addEventListener('error', this.onError.bind(this));
  //   this.gameXHR.addEventListener('load', this.onGameLoad.bind(this));
  // }

  action(data) {
    switch (data.action) {
      case 'init':
        //this.sendGetToken(data);
        this.lastAction = 'game_init';
        this.sendMessage({}, `game_init`, 'GET');
        break;
      case 'spin':
        this.sendSpin(data);
        break;
      case 'respin':
        this.sendRespin(data);
        break;
      case 'buy_bonus':
        this.lastAction = 'buy_bonus';
        this.sendMessage(data, 'buy_bonus');
        break;
      case 'balance':
        this.sendBalance();
        break;
    }
  }

  // sendInit(data) {
  //   // let requestData = {
  //   //     game_code: EntryPoint.UrlSettingsManager.gameCode,
  //   //     platform: EntryPoint.UrlSettingsManager.platform,
  //   //     casino_token: EntryPoint.UrlSettingsManager.casinoToken,
  //   //     currency: EntryPoint.UrlSettingsManager.currency,
  //   //     language: EntryPoint.UrlSettingsManager.language,
  //   //     play_for_fun: EntryPoint.UrlSettingsManager.playForFun,
  //   //     customer: EntryPoint.UrlSettingsManager.customer,
  //   //     player_uid: EntryPoint.UrlSettingsManager.playerUid,
  //   //     ext_params: EntryPoint.UrlSettingsManager.extParams
  //   // };
  //
  //   this.lastAction = 'init';
  //   this.sendMessage({}, 'init');
  // }
  async sendInit() {
    const data = await this.sendMessage({}, `game_init`, () => {
    }, 'GET', 'game_init');

    // const data =  await this.sendMessage({}, 'init')
    await window.OPWrapperService.init({
      partnerConfig: data.partner_config,
      language: data.lang,
      tournaments: data.tournaments,
      currencyInfo: {
        currency: data.currency,
        decimals: data.decimals,
      },
    });
    window.OPWrapperService.freeBetsController.setData(data.freebets);
    return await this.sendMessage({}, 'init');

  }

  sendSpin(data) {
    let requestData = {
      // session_token: XXXDataParser.sessionToken,
      betIndex: data.betIndex,
      type: 'bet',
    };

    /*if(this.sss < 1) {
        requestData.force_freebet = true;
        this.sss++;
    }*/

    this.lastAction = 'spin';
    this.sendMessage(requestData, 'bet');
  }

  sendRespin(data) {
    let requestData = {
      // game_code: EntryPoint.UrlSettingsManager.gameCode,
      // session_token: XXXDataParser.sessionToken,
      type: 'respin',
      // currency: EntryPoint.UrlSettingsManager.currency,
      // coin_value: data.bet,
      respin_reel_number: data.reel
    };

    //requestData.force_freebet = true;

    this.lastAction = 'respin';
    this.sendMessage(requestData, 'bet');
  }

  async sendMessage(data, action, callback = () => {}, method = 'POST', urlPath = 'game_request') {
    // Construct the URL with query parameters
    let url = `${this.adress}${urlPath}?sid=${this.sid}&gid=${this.gid}`;

    // Adding mandatory fields to the data object
    data.id = action;
    data.ver = '1.1';

    // Inject cheat parameters if applicable
    this._trySendCheatParams(data);

    // Configuration object for the fetch call
    const config = {
      method: method,
      ...(method === 'POST' && { body: JSON.stringify(data) })
    };

    let retryCount = 0;

    // Function to perform the fetch operation
    const send = async () => {
      try {
        const response = await fetch(url, config);
        const jsonResponse = await response.json();

        // Check for session expiration and retry logic
        if (action === 'game_init' && jsonResponse.code === window.OPWrapperService.errors.SESSION_EXPIRED.CODE && retryCount < 1) {
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 1000)); // Delay before retrying
          return send();
        } else {
          return jsonResponse;
        }
      } catch (error) {
        console.error(error);
        window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
        throw error;
      }
    };

    // Executing the send function and handling the callback
    try {
      const result = await send();
      const parsedResult = ErrorManager.parseResponseError(result);
      callback(parsedResult);
      return parsedResult;
    } catch (error) {
      if (error.name === 'OPWrapperError') {
        throw error;
      } else {
        console.error(error);
        window.OPWrapperService.showError(window.OPWrapperService.errors.REQUEST_FAILED.CODE);
      }
    }
  }

  sendBalance() {
    const requestData = {
      casino_token: EntryPoint.UrlSettingsManager.casinoToken,
    };

    this.lastAction = 'balance';
    this.sendMessage(requestData, 'balance')
  }

  onError(event) {
    console.log('ERROR', event);
  }

  _trySendCheatParams(data) {
    for (const cheatsParam of this.cheatsParams) {
      if (window[cheatsParam]) {
        data[cheatsParam] = window[cheatsParam];
        delete window[cheatsParam];
      }
    }
  }

  _tryGetSessionForDev() {
    const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
    if (devEnvironments.some(regexp => regexp.test(this.adress))) {
      const queryParams = queryString.parse(window.location.search.substr(1));
      if (!Object.keys(queryParams).length) {
        this.getSession();
      }
      this.adress = `https://${Utils.getUrlParam('api')}/`
    } else if (/192.168/.test(this.adress)) {
      this.adress = ''
    } else {
      this.adress = location.protocol + '//' + Utils.getUrlParam('api') + '/';
    }
  }

  async onGameLoad(event) {
    let data = JSON.parse(event.target.response);
    if (data.hasOwnProperty('success') && !data.success) {
      window.OPWrapperService.showError(data.code, data.message);
      console.error(data.message);
      return;
    }
    if (data.hasOwnProperty('error')) {
      this.onBackendError(data);
      return;
    }
    switch (this.lastAction) {
      case 'game_init':
        await this.onGameInit(data);
        this.sendInit();
        break;
      case 'init':
        this.onInitLoad(data);
        break;
      case 'spin':
        this.onSpinLoad(data);
        break;
      case 'respin':
        this.onRespinLoad(data);
        break;
      case 'buy_bonus':
        this.onBonusGameLoad(data);
        break;
      case 'balance':
        this.onBalanceLoad(data);
        break;
    }
  }

  onBackendError(data) {
    ErrorManager.backendError(data);
  }

  async onGameInit(data) {
    await window.OPWrapperService.init({
      partnerConfig: data.partner_config,
      language: data.lang,
      tournaments: data.tournaments,
      currencyInfo: {
        currency: data.currency,
        decimals: data.decimals,
      },
    });
  }

  onInitLoad(data) {
    let pData = XXXDataParser.parseInit(data);
    console.log(pData);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', pData);
  }

  onSpinLoad(data) {
    let pData = XXXDataParser.parseSpin(data);
    console.log(pData);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', pData);
  }

  onRespinLoad(data) {
    let pData = XXXDataParser.parseRespin(data);
    console.log(pData);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', pData);
  }

  onBonusGameLoad(data) {
    let pData = XXXDataParser.parsePurchaseBonusGame(data);
    console.log(pData);
    GlobalDispatcher.dispatch('dataProvider:hasResponse', pData);
  }

  onBalanceLoad(data) {
    EntryPoint.GameModel.balance = data.balance;
    data.action = 'balance';
    GlobalDispatcher.dispatch('dataProvider:hasResponse', data);
  }
}
