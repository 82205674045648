import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import SoundManager from 'Engine/soundManager/SoundManager';
import StatesManager from 'Engine/base/states/StatesManager';
import BaseSlotLinesContainer from 'Engine/base/lines/BaseSlotLinesContainer';
import { eSounds } from '../enums/eSounds';

export default class SlotLinesContainer extends BaseSlotLinesContainer {
  constructor(data, displayItemData) {
    super(data, displayItemData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('stateStart:showExpandedWinState', this.onShowExpandedWinStateStart, this);
    GlobalDispatcher.add('stateEnd:showExpandedWinState', this.onShowExpandedWinStateEnd, this);

    GlobalDispatcher.add('winningsController:startShowWinnings', this.onStartShowWinnings, this);
    GlobalDispatcher.add('winningsController:allWinningsComplete', this.onAllWinningsComplete, this);
  }

  onStartShowWinnings() {
    this.isComplete = false;
  }

  onAllWinningsComplete() {
    this.isComplete = true;
  }

  onShowExpandedWinStateStart() {
    let index = 0;
    this.lines[index].show(true);
    this.interval = setInterval(() => {
      index++;
      if (index >= EntryPoint.GameModel.lines) {
        GlobalDispatcher.dispatch('allExpandedSymbolLinesShowed');
        clearInterval(this.interval);
      } else {
        this.lines[index].show(true);
      }
    }, 250);
  }

  onShowExpandedWinStateEnd() {
    clearInterval(this.interval);
    this.hideLines();
  }

  showWinLine(data) {
    this.hideLines();
    let winLine = this.lines[data.line - 1];
    let volume = StatesManager.currentState.stateData.name === 'showBigWinState' ? 0 : eSounds.SMALL_WIN.volume;
    if (winLine) {
      winLine.show(true);
      if (!this.isComplete) {
        SoundManager.play(eSounds.SMALL_WIN.name, volume, false, 'winning');
      }
      GlobalDispatcher.dispatch('linesContainer:slowWinLine', data);
    } else {
      if (!this.isComplete) {
        SoundManager.play(eSounds.SMALL_WIN.name, volume, false, 'winning');
      }
    }
  }
}
