export const eSounds = {
  BACKGROUND: {
    name: 'Background1',
    volume: 0.5,
    group: 'music'
  },
  FREE_SPINS_BACK: {
    name: 'BackgroundFreeSpins',
    volume: 0.6,
    group: 'music'
  },
  REEL_STOP: {
    name: 'SymbolsStop',
    volume: 0.5,
    group: 'default'
  },
  SCATTER_STOP: {
    name: 'ScatterStop',
    volume: 1,
    group: 'default'
  },
  BONUS_STOP: {
    name: 'BonusSymbolStop',
    volume: 0.5,
    group: 'default'
  },
  SYMBOL_EXPAND: {
    name: 'SymbolExpand',
    volume: 0.5,
    group: 'default'
  },
  SMALL_WIN: {
    name: 'SmallWin',
    volume: 0.8,
    group: 'winning'
  },
  ANTISIPATOR: {
    name: 'Anticipation',
    volume: 0.7,
    group: 'default'
  },
  BIG_WIN_BACK: {
    name: 'BigWinBackground',
    volume: 0.7,
    group: 'winning'
  },
  BIG_WIN: {
    name: 'BigWin',
    volume: 1,
    group: 'winning'
  },
  MEGA_WIN: {
    name: 'SuperWin',
    volume: 1.2,
    group: 'winning'
  },
  EPIC_WIN: {
    name: 'SuperMegaBigWin',
    volume: 1,
    group: 'winning'
  },
  SCATTER_WIN: {
    name: 'FreespinsWin',
    volume: 1,
    group: 'default'
  },
  EST_BUTTON_CLICK: {
    name: 'ButtonClick',
    volume: 1,
    group: 'default'
  }
};

export const soundsPreloaderConfig = Object.fromEntries(Object.keys(eSounds).map(key => [key, eSounds[key].name]));
