import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class ShowBonusState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('allBonusesShown', () => setTimeout(this.onAllBonusesShown.bind(this), 500));
  }

  onAllBonusesShown() {
    if (!this.active) return;

    this.complete();
  }
}
