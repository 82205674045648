import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import StatesManager from 'Engine/base/states/StatesManager';
import EntryPoint from '../libs/game-engine/src/EntryPoint';

export default class GameInfoContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();
    this.hide();
  }

  init() {
    this.container = this.getChildByName('container');

    this.content = this.container ? this.container.getChildByName('content') : this.getChildByName('content');

    this.controls = this.getChildByName('controls');
    this.pagesCount = this.content.layersData[0].frameCount;

    let range = [];
    for (let i = 1; i <= this.pagesCount; i++) {
      range.push(i);
    }

    this.controls.setRange(range);
    this.content.goToFrame(this.controls.currentPage);
  }

  addListeners() {
    GlobalDispatcher.add('ui:paytableClick', this.show.bind(this));
    GlobalDispatcher.add('gameInfo:backClick', this.hide.bind(this));
    GlobalDispatcher.add('ui:spinClick', this.hide.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    if (EntryPoint.mobile && this.container) {
      if (data.isLandscape) {
        const scale = 1.46;
        this.container.x = this.container.x !== 0 ? this.container.x : this.container.x - this.container.displayData.width / 2;
        this.container.y = this.container.y !== 0 ? this.container.y : this.container.y - this.container.displayData.height / (scale * 2);
        this.container.scale.set(scale);
      } else {
        this.container.x = 0;
        this.container.y = 0;
        this.container.scale.set(1);
      }
      // this.container.goToFrame(frameIndex);
      // this.init();
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
