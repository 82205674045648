
export default class SymbolWinLabel {
  constructor(container, styles) {
    this.label = new PIXI.BitmapText('', styles);
    this.setAnchor(0.5,0.5);
    container.addChild(this.label);
    this.active = false;
  }

  setAnchor(x, y) {
    this.label.anchor.set(x, y);
  }

  destroy(options) {
    this.label.destroy(options);
  }

  set text(value) {
    if (typeof value !== 'string') value = '';
    this.active = !!value;

    this.label.text = value;
  }

  get text() {
    return this.label.text;
  }

  set x(value) {
    this.label.x = value;
  }

  get x() {
    return this.label.x;
  }

  set y(value) {
    this.label.y = value;
  }

  get y() {
    return this.label.y;
  }
}
